<template>
  <svg
    width="20px"
    height="20px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    viewBox="0 0 28 28" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path clip-rule="evenodd" d="M8.56581 6.77138C8.56581 3.58394 11.1852 1 14.4163 1C17.6474 1 20.2667 3.58394 20.2667 6.77138V12.5428C20.2667 15.7302 17.6474 18.3141 14.4163 18.3141C11.1852 18.3141 8.56581 15.7302 8.56581 12.5428V6.77138ZM14.4163 3.30855C12.4776 3.30855 10.906 4.85891 10.906 6.77138V12.5428C10.906 14.4552 12.4776 16.0056 14.4163 16.0056C16.3549 16.0056 17.9265 14.4552 17.9265 12.5428V6.77138C17.9265 4.85891 16.3549 3.30855 14.4163 3.30855Z" fill="#000000" fill-rule="evenodd"></path><path d="M12.5039 24.8743C12.9457 24.8743 13.3039 24.5161 13.3039 24.0743V22.6464C8.4263 22.1156 4.55224 18.2986 4.0067 13.4892C3.93387 12.8471 4.46474 12.3211 5.11096 12.3211C5.75718 12.3211 6.27205 12.848 6.36576 13.4874C6.93878 17.3972 10.3496 20.401 14.4717 20.401C18.5937 20.401 22.0046 17.3972 22.5776 13.4874C22.6713 12.848 23.1862 12.3211 23.8324 12.3211C24.4786 12.3211 25.0095 12.8471 24.9367 13.4892C24.3913 18.2971 20.5195 22.1132 15.644 22.6459V24.0743C15.644 24.5161 16.0022 24.8743 16.444 24.8743H18.6747C19.1165 24.8743 19.4747 25.2324 19.4747 25.6743V26.3828C19.4747 26.8246 19.1165 27.1828 18.6747 27.1828H10.3272C9.88539 27.1828 9.52721 26.8246 9.52721 26.3828V25.6743C9.52721 25.2324 9.88538 24.8743 10.3272 24.8743H12.5039Z" fill="#000000"></path></g>
  </svg>
</template>
