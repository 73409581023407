/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Randomization = {
    choose?: Randomization.choose;
    seed?: number;
};
export namespace Randomization {
    export enum choose {
        FIRST = 'first',
        LAST = 'last',
        SEED = 'seed',
    }
}

