<template>
  <router-link class="navbar-brand" :to="{ name: 'root' }">
    <img
      src="/odd-e.png"
      width="35"
      height="35"
      class="d-inline-block align-top"
      alt=""
    />
    Doughnut
  </router-link>
</template>
