<template>
  <svg
    width="25px"
    height="25px"
    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512" xml:space="preserve">
<g>
	<g>
		<path
d="M427.023,59.858h-68.944v-0.534c0-13.851-11.268-25.119-25.119-25.119h-35.502C293.703,14.743,276.542,0,256,0
			c-20.541,0-37.703,14.743-41.458,34.205H179.04c-13.851,0-25.119,11.268-25.119,25.119v0.534H84.977
			c-13.851,0-25.119,11.268-25.119,25.119v273.637c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017V84.977
			c0-5.01,4.076-9.086,9.086-9.086h17.637v385.336c0,4.427,3.589,8.017,8.017,8.017H401.37c4.428,0,8.017-3.589,8.017-8.017v-299.29
			c0-4.427-3.588-8.017-8.017-8.017c-4.428,0-8.017,3.589-8.017,8.017v291.273H118.647V75.891h35.274v17.637
			c0,4.427,3.589,8.017,8.017,8.017h188.125c4.428,0,8.017-3.589,8.017-8.017V75.891h35.273v43.29c0,4.427,3.588,8.017,8.017,8.017
			c4.428,0,8.017-3.589,8.017-8.017v-43.29h17.637c5.01,0,9.086,4.076,9.086,9.086v401.904c0,5.01-4.076,9.086-9.086,9.086H84.977
			c-5.01,0-9.086-4.076-9.086-9.086V401.37c0-4.427-3.589-8.017-8.017-8.017s-8.017,3.589-8.017,8.017v85.512
			c0,13.851,11.268,25.119,25.119,25.119h342.046c13.851,0,25.119-11.268,25.119-25.119V84.977
			C452.142,71.126,440.874,59.858,427.023,59.858z M342.046,85.511H169.954V59.324c0-5.01,4.076-9.086,9.086-9.086h42.756
			c4.427,0,8.017-3.589,8.017-8.017c0-14.44,11.748-26.188,26.188-26.188c14.441,0,26.188,11.748,26.188,26.188
			c0,4.427,3.588,8.017,8.017,8.017h42.756c5.01,0,9.086,4.076,9.086,9.086V85.511z"/>
	</g>
</g>
<g>
	<g>
		<path
d="M256,25.653c-4.427,0-8.017,3.589-8.017,8.017v8.551c0,4.427,3.589,8.017,8.017,8.017c4.428,0,8.017-3.589,8.017-8.017
			V33.67C264.017,29.243,260.428,25.653,256,25.653z"/>
	</g>
</g>
<g>
	<g>
		<path
d="M363.979,313.176l-85.511-171.023c-4.282-8.565-12.891-13.886-22.467-13.886c-9.575,0-18.185,5.321-22.467,13.886
			l-85.512,171.023c-6.194,12.387-1.155,27.506,11.234,33.7c12.388,6.194,27.506,1.156,33.7-11.234l31.538-63.074h63.014
			l31.537,63.074c6.126,12.254,21.413,17.375,33.701,11.234C365.134,340.681,370.173,325.564,363.979,313.176z M345.225,332.702
			c-4.394,1.98-9.692,0.066-11.84-4.23l-33.754-67.506c-1.359-2.716-4.133-4.432-7.17-4.432h-72.923
			c-3.037,0-5.813,1.716-7.17,4.432l-33.753,67.506c-2.242,4.482-7.71,6.303-12.19,4.064c-4.481-2.24-6.304-7.709-4.064-12.19
			l85.511-171.023c3.286-6.572,12.968-6.572,16.254,0l85.511,171.023C351.926,324.921,349.868,330.61,345.225,332.702z"/>
	</g>
</g>
<g>
	<g>
		<path
d="M282.53,226.761l-19.36-38.719c-2.897-5.792-11.444-5.794-14.34,0l-19.359,38.719c-2.621,5.24,1.323,11.602,7.17,11.602
			h38.719C281.207,238.363,285.152,232.001,282.53,226.761z M249.612,222.33L256,209.553l6.389,12.776H249.612z"/>
	</g>
</g>
<g>
	<g>
		<path
d="M350.063,145.37h-9.086v-9.086c0-4.427-3.588-8.017-8.017-8.017c-4.428,0-8.017,3.589-8.017,8.017v9.086h-9.086
			c-4.428,0-8.017,3.589-8.017,8.017s3.588,8.017,8.017,8.017h9.086v9.086c0,4.427,3.588,8.017,8.017,8.017
			c4.428,0,8.017-3.589,8.017-8.017v-9.086h9.086c4.428,0,8.017-3.589,8.017-8.017S354.491,145.37,350.063,145.37z"/>
	</g>
</g>
<g>
	<g>
		<path
d="M324.409,376.251H187.591c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h136.818
			c4.428,0,8.017-3.589,8.017-8.017C332.426,379.84,328.838,376.251,324.409,376.251z"/>
	</g>
</g>
<g>
	<g>
		<path
d="M264.551,410.455h-76.96c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h76.96
			c4.428,0,8.017-3.589,8.017-8.017C272.568,414.044,268.98,410.455,264.551,410.455z"/>
	</g>
</g>
</svg>
</template>
