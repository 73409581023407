<template>
  <svg
    width="25px"
    height="25px"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 473.931 473.931"
    style="enable-background: new 0 0 473.931 473.931"
    xml:space="preserve"
  >
    <circle style="fill: #ffc10e" cx="236.966" cy="236.966" r="236.966" />
    <path
      style="fill: #333333"
      d="M81.391,237.127c0,85.911,69.649,155.56,155.56,155.56c85.915,0,155.567-69.649,155.567-155.56
	H81.391z"
    />
    <path
      style="fill: #ca2027"
      d="M150.452,298.705c0,47.771,38.731,86.498,86.498,86.498c47.775,0,86.502-38.731,86.502-86.498
	H150.452z"
    />
    <g>
      <circle style="fill: #333333" cx="164.937" cy="155.231" r="37.216" />
      <circle style="fill: #333333" cx="305.664" cy="155.231" r="37.216" />
    </g>
  </svg>
</template>
