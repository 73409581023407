<template>
  <svg
    width="25px"
    height="25px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <!-- First folder -->
    <path
      d="M80,15H45l-10-10H20c-5,0-10,5-10,10v30c0,5,5,10,10,10h60c5,0,10-5,10-10V25C90,20,85,15,80,15z"
      fill="currentColor"
      opacity="0.3"
    />
    <!-- Second folder with plus -->
    <path
      d="M80,45H45l-10-10H20c-5,0-10,5-10,10v30c0,5,5,10,10,10h60c5,0,10-5,10-10V55C90,50,85,45,80,45z"
      fill="currentColor"
    />
    <!-- Plus sign -->
    <path
      d="M70,65H55V50c0-2.8-2.2-5-5-5s-5,2.2-5,5v15H30c-2.8,0-5,2.2-5,5s2.2,5,5,5h15v15c0,2.8,2.2,5,5,5s5-2.2,5-5V75h15c2.8,0,5-2.2,5-5S72.8,65,70,65z"
      fill="white"
    />
  </svg>
</template>
