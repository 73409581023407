<template>
  <svg
    width="20px"
    height="20px"
    ersion="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 59.985 59.985"
    style="enable-background: new 0 0 59.985 59.985"
    xml:space="preserve"
  >
    <g>
      <path
        d="M5.243,44.844L42.378,7.708l9.899,9.899L15.141,54.742L5.243,44.844z"
      />
      <path
        d="M56.521,13.364l1.414-1.414c1.322-1.322,2.05-3.079,2.05-4.949s-0.728-3.627-2.05-4.949S54.855,0,52.985,0
		s-3.627,0.729-4.95,2.051l-1.414,1.414L56.521,13.364z"
      />
      <path
        d="M4.099,46.527L0.051,58.669c-0.12,0.359-0.026,0.756,0.242,1.023c0.19,0.19,0.446,0.293,0.707,0.293
		c0.106,0,0.212-0.017,0.316-0.052l12.141-4.047L4.099,46.527z"
      />
      <path
        d="M43.793,6.294l1.415-1.415l9.899,9.899l-1.415,1.415L43.793,6.294z"
      />
    </g>
  </svg>
</template>
