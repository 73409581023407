<template>
  <svg
    width="25px"
    height="25px"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 473.935 473.935"
    style="enable-background: new 0 0 473.935 473.935"
    xml:space="preserve"
  >
    <circle style="fill: #ffc10e" cx="236.967" cy="236.967" r="236.967" />
    <g>
      <path
        style="fill: #333333"
        d="M356.671,354.1c-66.226-67.618-174.255-67.337-240.096,0.703
		c-8.389,8.666,4.827,21.912,13.227,13.227c58.87-60.83,154.386-61.204,213.641-0.703C351.896,375.96,365.116,362.721,356.671,354.1
		L356.671,354.1z"
      />
      <circle style="fill: #333333" cx="164.938" cy="155.232" r="37.216" />
      <circle style="fill: #333333" cx="305.667" cy="155.232" r="37.216" />
    </g>
  </svg>
</template>
