<template>
  <svg
    width="25px"
    height="25px"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 495.558 495.558"
    style="enable-background: new 0 0 495.558 495.558"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M492.538,242.721c-2.453-4.091-42.218-69.193-113.117-112.662L440.99,68.49l-13.923-13.923l-65.414,65.414
			c-32.499-16.885-70.607-28.587-113.874-28.587c-152.471,0-241.058,145.154-244.76,151.327L0,247.779l3.019,5.058
			c2.453,4.091,42.218,69.193,113.117,112.662l-61.569,61.568L68.49,440.99l65.414-65.414
			c32.499,16.885,70.607,28.587,113.874,28.587c152.471,0,241.058-145.154,244.76-151.327l3.019-5.058L492.538,242.721z
			 M130.822,350.813c-58.966-34.957-96.725-86.771-107.629-103.034c16.962-25.298,98.817-136.692,224.587-136.692
			c36.945,0,70.066,9.65,98.901,23.868l-30.372,30.372c-18.601-15.487-42.489-24.827-68.529-24.827
			c-59.144,0-107.269,48.125-107.269,107.279c0,26.037,9.338,49.924,24.822,68.525L130.822,350.813z M316.215,193.266
			c11.953,14.975,19.15,33.906,19.15,54.513c0,48.298-39.288,87.587-87.587,87.587c-20.605,0-39.535-7.198-54.51-19.153
			L316.215,193.266z M179.347,302.287c-11.95-14.974-19.145-33.903-19.145-54.508c0-48.298,39.288-87.587,87.577-87.587
			c20.608,0,39.538,7.198,54.513,19.15L179.347,302.287z M247.779,384.471c-36.945,0-70.066-9.65-98.901-23.868l30.376-30.376
			c18.6,15.489,42.488,24.831,68.525,24.831c59.154,0,107.279-48.125,107.279-107.279c0-26.04-9.34-49.928-24.827-68.529
			l34.505-34.505c58.966,34.957,96.725,86.771,107.629,103.034C455.404,273.077,373.548,384.471,247.779,384.471z"
        />
      </g>
    </g>
  </svg>
</template>
