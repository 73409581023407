<template>
  <svg
    width="20px"
    height="20px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 485 485"
    style="enable-background: new 0 0 485 485"
    xml:space="preserve"
  >
    <g>
      <circle cx="243" cy="243" r="243" fill="black"></circle>
      <polygon
        points="181.062,336.575 343.938,242.5 181.062,148.425 	"
        fill="white"
      />
    </g>
  </svg>
</template>
