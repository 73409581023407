<template>
  <svg
    width="20px"
    height="20px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <path
      style="fill: #fee187"
      d="M287.559,414.049c0-46.081,37.357-83.439,83.44-83.439V176.793H92.391v280.789h207.42
	C292.043,444.908,287.559,430.004,287.559,414.049z"
    />
    <g>
      <path
        style="fill: #ffc61b"
        d="M161.679,381.158c-8.014,0-14.511-6.498-14.511-14.511v-92.871c0-8.013,6.497-14.511,14.511-14.511
		s14.511,6.498,14.511,14.511v92.871C176.19,374.66,169.693,381.158,161.679,381.158z"
      />
      <path
        style="fill: #ffc61b"
        d="M231.695,381.158c-8.014,0-14.511-6.498-14.511-14.511v-92.871c0-8.013,6.497-14.511,14.511-14.511
		c8.014,0,14.511,6.498,14.511,14.511v92.871C246.206,374.66,239.709,381.158,231.695,381.158z"
      />
      <path
        style="fill: #ffc61b"
        d="M454.44,399.538c-8.014,0-14.511,6.498-14.511,14.511c0,38.007-30.922,68.929-68.928,68.929
		c-25.574,0-47.924-14.01-59.82-34.75c-0.302-0.528-0.612-1.055-0.9-1.59c-0.342-0.636-0.666-1.28-0.988-1.927
		c-0.313-0.631-0.618-1.267-0.911-1.907c-0.263-0.57-0.53-1.139-0.778-1.718c-0.498-1.165-0.968-2.342-1.399-3.532
		c-0.145-0.399-0.273-0.808-0.411-1.21c-0.329-0.964-0.641-1.933-0.927-2.909c-0.123-0.422-0.245-0.846-0.361-1.271
		c-0.328-1.209-0.63-2.423-0.892-3.65c-0.03-0.142-0.068-0.28-0.097-0.424c-0.289-1.395-0.527-2.801-0.73-4.214
		c-0.049-0.345-0.089-0.695-0.133-1.043c-0.141-1.1-0.254-2.206-0.342-3.314c-0.03-0.392-0.065-0.784-0.09-1.177
		c-0.089-1.428-0.148-2.859-0.148-4.295c0-38.006,30.922-68.928,68.928-68.928c8.014,0,14.511-6.498,14.511-14.511V191.301h20.317
		c8.014,0,14.511-6.498,14.511-14.511v-49.213c0-32.872-26.744-59.617-59.616-59.617c-8.014,0-14.511,6.498-14.511,14.511
		s6.497,14.511,14.511,14.511c16.869,0,30.594,13.725,30.594,30.595v34.705h-20.317H92.39H72.073v-34.705
		c0-16.871,13.725-30.595,30.594-30.595h150.795c8.014,0,14.511-6.498,14.511-14.511s-6.497-14.511-14.511-14.511h-85.615V29.022
		h127.697v53.449c0,8.013,6.497,14.511,14.511,14.511s14.511-6.498,14.511-14.511v-67.96C324.566,6.498,318.069,0,310.054,0h-156.72
		c-8.014,0-14.511,6.498-14.511,14.511V67.96h-36.157c-32.872,0-59.616,26.745-59.616,59.617v49.216
		c0,8.013,6.497,14.511,14.511,14.511h20.317v266.278c0,8.013,6.497,14.511,14.511,14.511h199.771
		C310.014,496.277,338.701,512,371,512c54.01,0,97.95-43.941,97.95-97.951C468.951,406.036,462.453,399.538,454.44,399.538z
		 M282.806,371.459c-0.199,0.412-0.38,0.833-0.575,1.247c-0.448,0.961-0.894,1.921-1.312,2.898
		c-0.226,0.531-0.437,1.069-0.654,1.603c-0.357,0.878-0.712,1.756-1.045,2.647c-0.219,0.585-0.422,1.177-0.63,1.767
		c-0.302,0.855-0.596,1.714-0.875,2.58c-0.199,0.618-0.385,1.239-0.572,1.863c-0.255,0.858-0.502,1.718-0.736,2.584
		c-0.171,0.637-0.337,1.277-0.495,1.918c-0.215,0.871-0.415,1.746-0.607,2.625c-0.141,0.646-0.28,1.293-0.408,1.944
		c-0.176,0.897-0.332,1.799-0.485,2.705c-0.107,0.643-0.219,1.284-0.313,1.931c-0.139,0.946-0.252,1.901-0.364,2.856
		c-0.071,0.614-0.154,1.226-0.213,1.844c-0.103,1.058-0.174,2.126-0.244,3.194c-0.033,0.522-0.083,1.042-0.109,1.567
		c-0.078,1.598-0.122,3.203-0.122,4.818c0,1.499,0.045,2.995,0.113,4.488c0.022,0.469,0.051,0.936,0.08,1.402
		c0.064,1.056,0.144,2.11,0.241,3.162c0.045,0.489,0.086,0.978,0.138,1.464c0.151,1.408,0.325,2.812,0.537,4.21
		c0.054,0.355,0.123,0.704,0.18,1.056c0.177,1.088,0.37,2.175,0.583,3.256c0.107,0.544,0.225,1.087,0.341,1.628
		c0.203,0.943,0.421,1.882,0.652,2.818c0.119,0.483,0.235,0.969,0.361,1.45c0.36,1.37,0.742,2.734,1.161,4.086H106.901V191.305
		h249.588v125.868c-0.065,0.01-0.129,0.023-0.194,0.033c-0.509,0.077-1.011,0.177-1.519,0.263c-1.038,0.174-2.075,0.35-3.101,0.556
		c-0.599,0.12-1.19,0.261-1.785,0.393c-0.926,0.205-1.85,0.409-2.766,0.64c-0.62,0.155-1.233,0.328-1.849,0.496
		c-0.879,0.239-1.757,0.483-2.626,0.747c-0.621,0.189-1.236,0.387-1.852,0.588c-0.852,0.277-1.701,0.563-2.542,0.862
		c-0.609,0.218-1.216,0.44-1.82,0.669c-0.839,0.318-1.67,0.647-2.497,0.987c-0.588,0.242-1.174,0.483-1.756,0.736
		c-0.836,0.363-1.663,0.742-2.486,1.126c-0.554,0.26-1.112,0.514-1.66,0.784c-0.855,0.419-1.696,0.858-2.535,1.3
		c-0.499,0.264-1.003,0.518-1.498,0.789c-0.927,0.509-1.839,1.043-2.747,1.582c-0.39,0.231-0.788,0.451-1.174,0.686
		c-1.307,0.798-2.593,1.628-3.858,2.484V273.78c0-8.013-6.497-14.511-14.511-14.511s-14.511,6.498-14.511,14.511v89.615
		c-0.014,0.025-0.032,0.048-0.046,0.073C285.586,366.053,284.133,368.721,282.806,371.459z"
      />
      <path
        style="fill: #ffc61b"
        d="M399.048,386.002c-5.667-5.665-14.855-5.665-20.523,0l-7.525,7.525l-7.525-7.525
		c-5.667-5.665-14.855-5.665-20.523,0c-5.667,5.667-5.667,14.855,0,20.523l7.525,7.525l-7.525,7.525
		c-5.667,5.667-5.667,14.855,0,20.523c2.834,2.833,6.547,4.25,10.261,4.25c3.713,0,7.428-1.418,10.261-4.25l7.525-7.525l7.525,7.525
		c2.834,2.833,6.547,4.25,10.261,4.25s7.428-1.418,10.261-4.25c5.667-5.667,5.667-14.855,0-20.523l-7.525-7.525l7.525-7.525
		C404.713,400.857,404.713,391.668,399.048,386.002z"
      />
    </g>
  </svg>
</template>
