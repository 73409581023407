<template>
<svg viewBox="0 0 202.886 202.886" >
    <circle style="fill: #ffc10e" cx="50%" cy="50%" r="115" />
<g>
	<g>
		<path
style="fill:#010002;" d="M30.312,109.779c-17.314,17.314-17.314,45.484,0,62.798s45.48,17.314,62.795,0l1.485-1.482
			L31.794,108.29L30.312,109.779z M88.611,171.049c-15.765,14.215-40.148,13.725-55.322-1.449
			c-15.185-15.174-15.665-39.561-1.457-55.325L88.611,171.049z"/>
		<path
style="fill:#010002;" d="M201.456,1.046c-0.934-0.816-2.176-1.185-3.407-0.995L166.43,4.847
			c-0.884,0.136-1.704,0.548-2.333,1.185L83.161,86.967c-21.813-8.947-47.849-4.595-65.539,13.095
			c-23.495,23.488-23.495,61.717,0,85.205c23.488,23.488,61.717,23.488,85.205,0c16.244-16.244,21.24-39.536,15.006-60.128
			l32.528-32.528c1.059-1.056,1.474-2.591,1.092-4.037l-4.527-17.307l17.551,4.352c1.435,0.354,2.945-0.068,3.987-1.113l7.655-7.652
			c1.013-1.016,1.439-2.484,1.135-3.883l-2.194-9.867l12.147,0.684c1.195,0.064,2.362-0.376,3.207-1.22l10.482-10.482
			c0.769-0.762,1.206-1.797,1.228-2.881l0.762-34.901C202.916,3.057,202.39,1.866,201.456,1.046z M96.882,179.319
			c-20.213,20.213-53.107,20.213-73.313,0c-20.213-20.213-20.213-53.099,0-73.313c20.206-20.213,53.099-20.213,73.309,0
			c5.332,5.329,9.219,11.556,11.739,18.188c-0.004,0.855,0.326,1.682,0.841,2.412C115.285,144.562,111.122,165.083,96.882,179.319z
			 M193.751,37.328l-7.956,7.959l-15.808-0.895c-1.321-0.075-2.616,0.465-3.45,1.478c-0.848,1.002-1.177,2.351-0.895,3.633
			l2.906,13.038l-4.348,4.345l-22.121-5.483c-1.435-0.358-2.945,0.068-3.987,1.109c-0.007,0.004-0.011,0.011-0.018,0.018
			c-1.041,1.056-1.449,2.58-1.074,4.019l5.712,21.824l-28.241,28.241c-2.867-5.977-6.703-11.599-11.653-16.549
			c-3.607-3.604-7.587-6.592-11.782-9.09l78.03-78.026l25.306-3.837L193.751,37.328z"/>
	</g>
</g>
</svg>

</template>
