<template>
  <ContentLoader class="loading-bar" />
</template>

<script>
import { ContentLoader } from "vue-content-loader"

export default {
  components: {
    ContentLoader,
  },
}
</script>
