<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 485 485"
    style="enable-background: new 0 0 485 485"
    xml:space="preserve"
  >
    <g>
      <circle cx="243" cy="243" r="243" fill="rgba(50, 150, 50, 0.8)"></circle>
      <rect x="172.5" y="140" width="55" height="205" />
      <rect x="257.5" y="140" width="55" height="205" />
    </g>
  </svg>
</template>
