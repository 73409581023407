<template>
  <svg
    width="24px"
    height="24px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
   x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path
      d="M375.07,147.393L232.186,290.277l-95.256-95.256L0,331.951v96.7h512V284.323L375.07,147.393z M476.279,392.93H35.721
			v-46.184L136.93,245.537l95.256,95.256L375.07,197.909l101.209,101.209V392.93z"/>
	</g>
</g>
<g>
	<g>
		<path
      d="M232.186,83.349c-29.546,0-53.581,24.035-53.581,53.581s24.035,53.581,53.581,53.581s53.581-24.037,53.581-53.581
			S261.732,83.349,232.186,83.349z M232.186,154.791c-9.848,0-17.86-8.012-17.86-17.86s8.012-17.86,17.86-17.86
			s17.86,8.012,17.86,17.86S242.034,154.791,232.186,154.791z"/>
	</g>
	</g>
  </svg>
  </template>
