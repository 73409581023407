<template>
  <svg
    width="20px"
    height="20px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 52 52"
    xml:space="preserve"
  >

 <g data-name="Group 2" id="Group_2"><path d="M11.6,40H2a2,2,0,0,1-2-2V28.4A2,2,0,0,1,.59,27L27,.59a2,2,0,0,1,2.82,0l9.6,9.6a2,2,0,0,1,0,2.82L13,39.41A2,2,0,0,1,11.6,40ZM4,36h6.77l24.4-24.4L28.4,4.83,4,29.23Z"/><path d="M50,52H2a2,2,0,0,1,0-4H50a2,2,0,0,1,0,4Z"/></g>
  </svg>
</template>
