<template>
  <svg
    width="20px"
    height="20px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 455 455"
    style="enable-background: new 0 0 455 455"
    xml:space="preserve"
  >
    <rect y="212.5" width="455" height="30" />
  </svg>
</template>
