<template>
  <svg
    width="25px"
    height="25px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <!-- Parent folder -->
    <path
      d="M80,25H45l-10-10H20c-5,0-10,5-10,10v50c0,5,5,10,10,10h60c5,0,10-5,10-10V35C90,30,85,25,80,25z"
      fill="currentColor"
      opacity="0.3"
    />
    <!-- Child folder with plus -->
    <path
      d="M70,45H55V30c0-2.8-2.2-5-5-5s-5,2.2-5,5v15H30c-2.8,0-5,2.2-5,5s2.2,5,5,5h15v15c0,2.8,2.2,5,5,5s5-2.2,5-5V55h15c2.8,0,5-2.2,5-5S72.8,45,70,45z"
      fill="currentColor"
    />
  </svg>
</template>
